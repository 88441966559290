import { GroupedResources, Resource } from '@/@core/models/api/resourcesResponse';

export const groupAndSortResources = (resources: Resource[]): GroupedResources => {
  const groupedResources: GroupedResources = resources.reduce((groupedResource, resource) => {
    const { type } = resource;
    groupedResource[type] = groupedResource[type] || [];
    groupedResource[type].push(resource);
    return groupedResource;
  }, {});

  return Object.fromEntries(
    Object.entries(groupedResources).map(([key, value]) => [
      key,
      value.sort((a, b) => a.id.localeCompare(b.id)),
    ])
  );
};
