export class FeatureFlagClient<T extends Record<string, any>> {
  constructor(private flags: T) {}

  public getFlag<K extends keyof T>(flagKey: K, defaultValue: T[K]): T[K] {
    return this.flags[flagKey] ?? defaultValue;
  }

  public setFlags(flags: T): void {
    this.flags = flags;
  }
}
