import { TranscriptionContent } from '@/modules/transcription/models/TranscriptionContent';

export enum TranscriptionJobStatus {
  UNINITIALIZED = 'uninitialized',
  UPLOADING = 'uploading',
  TRANSCRIBING = 'transcribing',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}

export interface TranscriptionJob {
  selectedFile: File | undefined;
  jobId: string | undefined;
  transcriptionContent: TranscriptionContent | undefined;
  status: TranscriptionJobStatus;
  timestamp: number | undefined;
}
