import { ConfigResponse, DEFAULT_CONFIG } from '@/@core/models/api/configResponse';
import { ASSISTANT_SERVICE } from '@/@core/services/api/AssistantService';
import { HTTP_CLIENT } from '@/@core/services/api/httpClient';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useConfigStore = defineStore(
  'config',
  () => {
    const config = ref<ConfigResponse>(DEFAULT_CONFIG);

    const getConfig: () => Promise<void> = async () => {
      config.value = await ASSISTANT_SERVICE.configWithDefaults();
      HTTP_CLIENT.updateConfig({ timeout: config.value.requestTimeoutInSeconds * 1000 });
    };

    return {
      config,
      getConfig,
    };
  },
  {
    persist: {
      pick: ['config'],
    },
  }
);
