import { TranslationResource } from '@/@core/models/api/resourcesResponse';
import { ASSISTANT_SERVICE } from '@/@core/services/api/AssistantService';
import { t } from '@/i18n';
import { groupAndSortResources } from '@/modules/Resources/utils/groupAndSortResources';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

export const useTranslationResourcesStore = defineStore(
  'translationResources',
  () => {
    const notificationStore = useNotificationStore();
    const translationResources = ref<TranslationResource[]>([]);

    const getTranslationResources = async () => {
      try {
        const resources = await ASSISTANT_SERVICE.translationResources();
        const resourcesGroupedAndSorted = groupAndSortResources(resources);
        translationResources.value = resourcesGroupedAndSorted.MachineTranslation ?? [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        // TODO: differentiate error types (AST-664)
        console.log('Failed to get translation resources');
        translationResources.value = [];
      }
    };

    watch(translationResources, () => {
      if (!translationResources.value || translationResources.value.length === 0) {
        notificationStore.addErrorNotification(
          t('TRANSLATION_NOTIFICATIONS.translation-model-unavailable')
        );
      }
    });

    const selectedTranslationResource = computed(() => {
      // TODO Adjust this in AST-529
      return translationResources.value?.[0];
    });

    const translationResourcesIds = computed<string[]>(() => {
      return Object.values(translationResources.value).map((resource) => resource.id);
    });

    const translationResourcesDictionaryById = computed<Record<string, TranslationResource>>(() => {
      if (!translationResources.value) return {};
      return Object.values(translationResources.value).reduce((acc, resource) => {
        return {
          ...acc,
          [resource.id]: resource,
        };
      }, {});
    });

    return {
      getTranslationResources,
      selectedTranslationResource,
      translationResources,
      translationResourcesDictionaryById,
      translationResourcesIds,
    };
  },
  {
    persist: true,
  }
);
