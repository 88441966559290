import { HTTP_CLIENT } from '@/@core/services/api/httpClient';
import { authManager } from '@/@core/services/auth/auth';
import { User } from 'oidc-client-ts';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null);

  async function fetch() {
    if (!user.value) {
      user.value = await authManager.getUser();
      if (user.value !== null) {
        HTTP_CLIENT.setBearerToken(user.value.access_token);
      }
    }
  }

  function isInvalidUserSession() {
    return (
      user.value === null ||
      user.value.expired ||
      (user.value.expires_at && user.value.expires_at <= 10 * 60)
    );
  }

  return {
    user,
    fetch,
    isInvalidUserSession,
  };
});
