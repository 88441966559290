import { PiniaPlugin } from 'pinia';

export const piniaPluginLoadingState: PiniaPlugin = ({ options, store }) => {
  if (!options.loading) return;

  const loadingQueue = {};

  const handleActionEnd = (name) => {
    loadingQueue[name]--;
    if (!loadingQueue[name]) {
      delete store.loading[name];
    }
  };

  options.loading.forEach((name) => {
    loadingQueue[name] = 0;
  });

  store.$onAction(({ name, store, after, onError }) => {
    // @ts-expect-error TODO legacy code does not pass type checks
    if (!options.loading.includes(name)) return;
    loadingQueue[name]++;
    store.loading[name] = true;

    after(() => handleActionEnd(name));
    onError(() => handleActionEnd(name));
  });

  return {
    loading: {},
  };
};
