import { TRANSLATION_RESOURCE_SCHEMA } from '@/@core/models/api/resourcesResponse';
import { z } from 'zod';

export interface TranslateRequest {
  text: string;
  source_language: string;
  target_language: string;
}

const TRANSLATED_BY_SCHEMA = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  type: z.string().min(1),
  score: z.number().optional(),
});

const TRANSLATION_SEGMENT_SCHEMA = z.object({
  translation: z.string(),
  source: z.string(),
  translated_by: TRANSLATED_BY_SCHEMA,
});

export const TRANSLATION_RESPONSE_SCHEMA = z.object({
  translation: z.string(),
  translation_segments: z.array(TRANSLATION_SEGMENT_SCHEMA),
});
export type TranslationResponse = z.infer<typeof TRANSLATION_RESPONSE_SCHEMA>;

export const TRANSLATION_RESOURCES_RESPONSE_SCHEMA = z.array(TRANSLATION_RESOURCE_SCHEMA);
export type TranslationResourcesResponse = z.infer<typeof TRANSLATION_RESOURCES_RESPONSE_SCHEMA>;
