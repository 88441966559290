/**
 * Using this switch instead of passing the locale directly to the import statement because vite does not support dynamic imports with variables. and its important that this is dynamic because we do not want to add this initialization to the app startup time.
 */
const importLocaleErrorMap = async (locale: string) => {
  if (locale === 'de') {
    return await import(`@/locales/errorMessagesMap/de.json`);
  }
  return await import(`@/locales/errorMessagesMap/en.json`);
};

export const mapErrorsToUserFriendlyErrors = async (
  errorMessage: string,
  locale: string
): Promise<string> => {
  const errorMap = await importLocaleErrorMap(locale);
  const localeErrorMessage = errorMap.default[errorMessage];
  if (localeErrorMessage) {
    return localeErrorMessage;
  }

  return errorMap.default['default'];
};
