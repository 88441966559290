import { featureFlags } from '../api/FeatureFlagService';
import { FeatureFlagClient } from './FeatureFlagClient';
import { FeatureFlags } from '@/@core/models/FeatureFlags';

/**
 * The client instance for managing feature flags.
 */
export const featureFlagClient = new FeatureFlagClient<FeatureFlags>({});

/**
 * Retrieves the value of a feature flag based on the given key.
 */
export const useFeatureFlag = <K extends keyof FeatureFlags>(
  flagKey: K,
  defaultValue: FeatureFlags[K]
): FeatureFlags[K] => {
  return featureFlagClient.getFlag(flagKey, defaultValue);
};

/**
 * Initializes the feature flag client with the given configuration.
 */
export const initFeatureFlag = async (): Promise<FeatureFlagClient<FeatureFlags>> => {
  const config = await featureFlags();
  featureFlagClient.setFlags(config);
  return featureFlagClient;
};
