// https://stackoverflow.com/a/33647870/6744615
export const hashString = <T extends boolean>(
  str = '',
  toString?: T
): T extends true ? string : number => {
  if (toString) return window.btoa(str) as any;
  let hash = 0;
  for (let i = 0; i < str.length; ++i) {
    hash = Math.imul(31, hash) + str.charCodeAt(i);
  }

  return (hash | 0) as any;
};
