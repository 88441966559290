import { z } from 'zod';

export const PNG_CONTENT_TYPE = 'image/png';
export const PNG_CONTENT_TYPE_SCHEMA = z.literal(PNG_CONTENT_TYPE);
export type PngContentType = z.infer<typeof PNG_CONTENT_TYPE_SCHEMA>;

export const SVG_CONTENT_TYPE = 'image/svg+xml';
export const SVG_CONTENT_TYPE_SCHEMA = z.literal(SVG_CONTENT_TYPE);
export type SvgContentType = z.infer<typeof SVG_CONTENT_TYPE_SCHEMA>;

export const PNG_BLOB_RESPONSE_SCHEMA = z.instanceof(Blob);
export type PngBlobResponse = z.infer<typeof PNG_BLOB_RESPONSE_SCHEMA>;

export const SVG_CONTENT_RESPONSE_SCHEMA = z.string();
export type SvgContentResponse = z.infer<typeof SVG_CONTENT_RESPONSE_SCHEMA>;

export function isPngBlobResponse(favicon: unknown): favicon is PngBlobResponse {
  return PNG_BLOB_RESPONSE_SCHEMA.safeParse(favicon).success;
}
