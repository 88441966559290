import isoLanguages from '@cospired/i18n-iso-languages';
import localeDataDE from '@cospired/i18n-iso-languages/langs/de.json';
import localeDataEN from '@cospired/i18n-iso-languages/langs/en.json';

export const registerLocales = (): void => {
  isoLanguages.registerLocale(localeDataEN);
  isoLanguages.registerLocale(localeDataDE);
};

export const getLocalizedLanguageName = (languageCode: string, localeCode: string): string => {
  const [code, territory] = languageCode.split('-');

  const name = isoLanguages.getName(code, localeCode) || '';

  return territory ? `${name} (${territory})` : name;
};
