import {
  COLLECTION_RESOURCE_SCHEMA,
  LANGUAGE_MODEL_RESOURCE_SCHEMA,
} from '@/@core/models/api/resourcesResponse';
import { Locale } from '@/stores/useWorkspaceStore';
import { z } from 'zod';

export interface QaDocumentRequest {
  question: string;
  file: File;
  fallback_language?: Locale;
}

export interface QaDocumentIndexRequest {
  question: string;
  namespace: string;
  collection: string;
  fallback_language?: Locale;
}

export interface QaSourcesRequest {
  trace_id: string;
}

const SCORED_TEXT_HIGHLIGHT_SCHEMA = z.object({
  start: z.number().int().min(0),
  end: z.number().int().min(0),
  score: z.number().min(0).max(1),
});

const QA_SOURCES_SCHEMA = z.object({
  source_name: z.string(),
  source_text: z.string(),
  highlights: z.array(SCORED_TEXT_HIGHLIGHT_SCHEMA),
});

export const QA_DOCUMENT_RESPONSE_SCHEMA = z.object({
  answer: z.string().optional(),
  trace_id: z.string(),
  safe_output: z.boolean(),
});

export type QaDocumentResponse = z.infer<typeof QA_DOCUMENT_RESPONSE_SCHEMA>;

export const QA_DOCUMENT_INDEX_RESPONSE_SCHEMA = z.object({
  answer: z.string().optional(),
  trace_id: z.string(),
  safe_output: z.boolean(),
});
export type QaDocumentIndexResponse = z.infer<typeof QA_DOCUMENT_INDEX_RESPONSE_SCHEMA>;

export const QA_SOURCES_RESPONSE_SCHEMA = z.object({
  trace_id: z.string(),
  qa_sources: z.array(QA_SOURCES_SCHEMA),
});
export type QaSourcesResponse = z.infer<typeof QA_SOURCES_RESPONSE_SCHEMA>;

export const QA_RESOURCES_RESPONSE_SCHEMA = z.array(
  z.discriminatedUnion('type', [COLLECTION_RESOURCE_SCHEMA, LANGUAGE_MODEL_RESOURCE_SCHEMA])
);
export type QaResourcesResponse = z.infer<typeof QA_RESOURCES_RESPONSE_SCHEMA>;
