import { LANGUAGE_MODEL_RESOURCE_SCHEMA } from '@/@core/models/api/resourcesResponse';
import { AvailableSummaryLengths } from '@/modules/Summary/utils/constants';
import { Locale } from '@/stores/useWorkspaceStore';
import { z } from 'zod';

export interface SummaryTextRequest {
  text: string;
  summary_length: AvailableSummaryLengths;
  fallback_language: Locale;
}

export interface SummaryDocumentRequest {
  file: File;
  summary_length: AvailableSummaryLengths;
  fallback_language: Locale;
}

export const SUMMARY_RESPONSE_SCHEMA = z.object({
  summary: z.string(),
  trace_id: z.string(),
  safe_output: z.boolean(),
});

export type SummaryResponse = z.infer<typeof SUMMARY_RESPONSE_SCHEMA>;

export const SUMMARY_RESOURCES_RESPONSE_SCHEMA = z.array(LANGUAGE_MODEL_RESOURCE_SCHEMA);
export type SummaryResourcesResponse = z.infer<typeof SUMMARY_RESOURCES_RESPONSE_SCHEMA>;
