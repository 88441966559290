import { http } from '@aleph-alpha/lib-http';
import ky from 'ky';

export const HTTP_CLIENT = http({
  baseURL: '/api',
  retry: {
    limit: 5,
    methods: ['get'],
    statusCodes: [404],
  },
});

// TODO: AST-668
export const KY_HTTP_CLIENT = ky.create();
