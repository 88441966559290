import { Language } from './@core/models';
import { DEFAULT_APP_LOCALE } from '@/@core/constants/i18n';
import { getLocalizedLanguageName, registerLocales } from '@/@core/services/localization';
import { Locale } from '@/stores/useWorkspaceStore';
import { createI18n } from 'vue-i18n';

registerLocales();

export const i18n = createI18n({
  locale: DEFAULT_APP_LOCALE,
  fallbackLocale: DEFAULT_APP_LOCALE,
  legacy: false,
  messages: {
    en: {},
    de: {},
  },
  fallbackWarn: false,
  missingWarn: false,
});

export const setLocalMessages = async (locale: string) => {
  const messages = await import(`./locales/${locale}.json`);

  Object.entries(messages.default).forEach(([locale, messages]: any) => {
    const existingMessages = i18n.global.getLocaleMessage(locale) || {};
    i18n.global.setLocaleMessage(locale, { ...existingMessages, ...messages });
  });
};

export const translateLocaleOutsideVue = async (key: string, value?: any) => {
  // @ts-expect-error TODO legacy code does not pass type checks
  const localeFileName = key.split('.').shift().toLowerCase().replaceAll('_', '-');
  await setLocalMessages(localeFileName);
  return i18n.global.t(key, value);
};

export const t = (key: string, value?: any) => i18n.global.t(key, value);

export const supportedLocales = (locale: Locale): Language[] =>
  [...new Set([...i18n.global.availableLocales])].map((localeCode) => ({
    code: localeCode,
    languageName: getLocalizedLanguageName(localeCode, locale),
  }));

export default i18n;
