import { z } from 'zod';

export const LOGIN_RESPONSE_SCHEMA = z.object({
  token: z.string().min(1),
  email: z.string().email(),
});

export type LoginResponse = z.infer<typeof LOGIN_RESPONSE_SCHEMA>;

export interface LoginRequest {
  email: string;
  password: string;
}
