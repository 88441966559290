import { TranscriptionSegment } from '@/modules/transcription/models/TranscriptionContent';

export const formatSegmentsAsText = (segments: TranscriptionSegment[]): string => {
  const includeHours = maxStartTime(segments) >= 3600;
  return segments
    .map((segment) => `${rawSecondsToTimeString(segment.startTime, includeHours)}\n${segment.text}`)
    .join('\n\n\n');
};

const maxStartTime = (segments: TranscriptionSegment[]): number =>
  Math.max(...segments.map((segment) => segment.startTime));

const rawSecondsToTimeString = (rawSeconds: number, includeHours: boolean = false): string => {
  const roundedToFullSeconds = Math.round(rawSeconds);

  const dateObject = new Date(roundedToFullSeconds * 1000);
  const minutes = dateObject.getUTCMinutes();
  const seconds = dateObject.getUTCSeconds();

  let timeString = padTimeNumber(minutes) + ':' + padTimeNumber(seconds);

  if (includeHours) {
    const hours = dateObject.getUTCHours();
    timeString = hours + ':' + timeString;
  }

  return timeString;
};

const padTimeNumber = (timeNumber: number): string => timeNumber.toString().padStart(2, '0');
